// Override default variables before the import
$purple: #6e1646;
$primary: $purple;
$white: #ffffff;
$jumbotron-bg: $purple;
$jumbotron-color: $white;
$hr-border-color: $white;

.larger {
  font-size: larger;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
